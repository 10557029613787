import { useMemo } from 'react';

import { createSearchProvider, Scope } from '@atlassian/search-provider';

import { useSessionData } from '@confluence/session-data';

export function useSearchProvider() {
	const { cloudId } = useSessionData();
	return useMemo(
		() =>
			Promise.resolve(
				createSearchProvider(cloudId, Scope.ConfluencePageBlog, undefined, undefined, true),
			),
		[cloudId],
	);
}
