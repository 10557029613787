import { Scope } from '@atlassian/search-client';
import {
	type SearchProvider,
	type QuickSearchResult,
	type LinkContentType,
} from '@atlaskit/editor-common/provider-factory';

import SearchResource from './api/SearchResource';

export const createSearchProvider = (
	cloudId: string,
	scope: Scope,
	aggregatorUrl?: string,
	analyticsIdentifier?: string,
	useConfluenceTypeInAri?: boolean,
): SearchProvider => {
	return new SearchResource(
		cloudId,
		scope,
		aggregatorUrl,
		analyticsIdentifier,
		useConfluenceTypeInAri,
	);
};

export { SearchResource, Scope };
export type { SearchProvider, QuickSearchResult, LinkContentType };
